.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 20;
}

.wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 22;
}

.wrapper span {
    position: relative;
    color: var(--white);
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 10px;
    text-transform: uppercase;
    animation: animate 1.5s linear infinite;
}

.wrapper span:nth-child(1) {
    animation-delay: 0s;
}

.wrapper span:nth-child(2) {
    animation-delay: 0.1s;
}

.wrapper span:nth-child(3) {
    animation-delay: 0.2s;
}

.wrapper span:nth-child(4) {
    animation-delay: 0.3s;
}

.wrapper span:nth-child(5) {
    animation-delay: 0.4s;
}

.wrapper span:nth-child(6) {
    animation-delay: 0.5s;
}

.wrapper span:nth-child(7) {
    animation-delay: 0.6s;
}

.wrapper span:nth-child(8) {
    animation-delay: 0.7s;
}

.wrapper span:nth-child(9) {
    animation-delay: 0.8s;
}

.wrapper span:nth-child(10) {
    animation-delay: 0.9s;
}

.wrapper span:nth-child(11) {
    animation-delay: 1s;
}

.wrapper span:nth-child(12) {
    animation-delay: 1.1s;
}

@keyframes animate {
    0% {
        filter: blur(0);
    }

    40% {
        filter: blur(20px);
    }

    80% {
        filter: blur(0);
    }

    100% {
        filter: blur(0);
    }
}