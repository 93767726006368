.main {
    padding: 80px 20px;
    padding-bottom: 20px;
    min-height: calc(100vh - 134px);
}

.productsEmpty {
    font-size: 30px;
    color: var(--white);
    text-align: center;
    padding-top: 10px;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.cardList {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
}

.productWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.productContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.productImg {
    max-width: 350px;
}

.productName {
    font-weight: 600;
    font-size: 30px;
    color: white;
    text-align: center;
    text-wrap: balance;
}

.productPrice {
    font-weight: 600;
    font-size: 24px;
    color: white;
}

.productDescription > h1, .productProductsDescriptionTitle {
    font-size: 24px;
}

.productProductsDescriptionTitle, .productProductsFooter {
    padding: 0 20px;
}

.productDescription > * {
    line-height: 1.2;
    margin-bottom: 10px;
}

.productDescription > strong, .productDescription > * > strong, .productDescription > * > * > strong {
    font-size: 20px;
    display: block;
    margin: 10px 0;
}

.productDescription ul {
    list-style: disc;
    padding-left: 20px;
}

.productDescription table {
    border-collapse: collapse;
}

.productDescription table tbody tr td {
    border: 1px solid var(--gray5);
    padding: 5px;
}

.productDescription table tbody tr td:nth-child(even) {
    padding: 0 10px;
}

.productLink {
    font-size: 20px;
    color: #F2F3F7;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
}

.navFooter {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.productsPage {
    color: var(--white);
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.productDescriptionWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.productProducts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px;
    margin: 0;
    padding: 0 !important;
    list-style: none !important;
}

.productProductsItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.productProductsName {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
}

.aboutInfoSection, .aboutContactSection, .productDescription, .productProductsDescription, .articlesWrapper {
    padding: 20px;
    background: rgba(255,255,255,0.8);
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}

.productProductsDescription {
    padding: 20px 0;
}

.aboutInfoSection {
    margin-bottom: 20px;
}

.aboutContainer {
    margin-bottom: 20px;
}

.aboutInfoList {
    list-style: disc;
    padding-left: 20px;
}

.aboutTitle {
    font-size: 24px;
    margin-bottom: 10px;
}

.aboutSubTitle {
    font-size: 20px;
    margin-bottom: 10px;
}

.aboutTextWrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 0.3fr 0.7fr;
}

.aboutText {
    margin-bottom: 8px;
}

.aboutMB {
    margin-bottom: 16px;
}

.aboutTextStyle {
    color: var(--gray5);
}

.aboutSecondaryText {
    color: black;
    font-weight: 600;
}

/* parallax styles */

.parallax {
    background-color: var(--black3);
}

.layers {
    perspective: 1000px;
    overflow: hidden;
}

.layers__container {
    height: 100vh;
    transform-style: preserve-3d;
    transform: rotateX(var(--move-y)) rotateY(var(--move-x));
    will-change: transform;
    transition: transform var(--transition);
}

.layers__item {
    position: absolute;
    inset: -5vw;
    background-size: cover;
    background-position: center;
}

.layer_1 {
    background-image: url(../assets/parallax/layer1.jpg);
    transform: 
        translateZ(-55px) 
        scale(1.06);
}

.layer_2 {
    background-image: url(../assets/parallax/layer2.png);
    transform: 
        translateZ(80px)
        scale(0.88);
}

.layer_3 {
    transform: 
        translateZ(180px)
        scale(0.8);
    display: grid;
    place-items: center;
}

.layer_4 {
    transform: 
        translateZ(190px)
        scale(0.9);
}

.layer_5 {
    background-image: url(../assets/parallax/layer5.png);
    transform: 
        translateZ(300px)
        scale(0.8);
}

.layer_6 {
    background-image: url(../assets/parallax/layer6.png);
    transform: translateZ(380px);
}

.hero_content {
    display: grid;
    place-items: center;
    padding: 10vw;
}

.hero_content__title, .hero_content__text {
    font-family: kamerik-3d;
    color: rgb(255 255 255 / 0.9);
    font-size: calc(var(--index) * 5);
    letter-spacing: calc(var(--index) * -0.2);
    line-height: 0.8;
    text-align: center;
    margin-bottom: 30px;
}

.hero_content__text {
    font-size: calc(var(--index) * 4);
    letter-spacing: calc(var(--index) * -0.1);
}

.layer_4, .layer_5, .layer_6 {
    pointer-events: none;
}

.scrollImg {
    width: calc(var(--index) * 5);
    animation: scroll 1.5s linear infinite;
}

@keyframes scroll {
    10% {
        transform: translateY(5px);
    }

    33% {
        transform: translateY(-5px);
    }

    66% {
        transform: translateY(3px);
    }

    100% {
        transform: translateY(-3px);
    }
}

@media screen and (min-width: 768px) {
    .main {
        padding: 80px 60px;
        padding-bottom: 20px;
        min-height: calc(100vh - 94px);
    }

    .productMedia {
        display: flex;
        align-items: flex-start;
        gap: 20px;
    }

    .productWrapper {
        position: sticky;
        top: 64px;
        width: auto;
    }
}

@media screen and (min-width: 901px) {
    .main {
        padding: 100px 60px;
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 1024px) {
    .main {
        padding: 100px;
        padding-bottom: 20px;
    }

    .aboutWrapper {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;
        justify-content: center;
    }

    .aboutInfoSection {
        margin-bottom: 0;
    }

    .aboutContactSection {
        height: 100%;
        min-width: 260px;
    }

    .aboutTextWrapper {
        grid-template-columns: 0.2fr 0.8fr;
    }
}