.wrapper {
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
}

.wrapper li {
    display: grid;
    place-items: center;
}

.icon {
    width: 30px;
    transition: 200ms linear all;
}

.icon:hover {
    transform: rotate(45deg);
}