.enter {
    opacity: 0;
    transform: translateY(40px);
  }
  
.enterActive {
    opacity: 1;
    transform: translateY(0);
    transition: all .2s ease;
  }
  
.exit {
    opacity: 1;
    transform: translateY(0);
  }
  
.exitActive {
    opacity: 0;
    transform: translateY(40px);
    transition: all .2s ease;
  }