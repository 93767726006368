.enter {
    opacity: 0;
    transform: translate(-20px, -20px);
  }
  
  .enterActive {
    opacity: 1;
    transform: translate(0, 0);
    transition: all .2s ease;
  }
  
  .exit {
    opacity: 1;
    transform: translate(0, 0);
  }
  
  .exitActive {
    opacity: 0;
    transform: translate(-20px, -20px);
    transition: all .2s ease;
  }

  .anchor {
    position: fixed;
    right: 10px;
    bottom: 30px;
    width: 40px;
    height: 40px;
    transition: all 0.3s;
    z-index: 100;
  }

  .anchor:hover {
    transform: scale(1.2);
  }