.wrapper {
    position: relative;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1));
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.green {
    background: linear-gradient(135deg, rgba(83, 255, 60, 0.4), rgba(83, 255, 60, 0.1));
    border: 1px solid rgba(83, 255, 60, 0.18);
}

.bigWrapper {
    position: absolute;
    padding: 10px;
    bottom: 60px;
    left: 10px;
    right: 10px;
    background: rgb(102, 185, 235);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.img {
    margin: 0 auto;
}

.name {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: white;
    overflow-wrap: anywhere;
    word-wrap: anywhere;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    text-wrap: balance;
    margin-bottom: 5px;
}

.price {
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 5px;
}

.description {
    text-align: center;
    color: white;
    overflow-wrap: anywhere;
    word-wrap: anywhere;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.bigName {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: white;
    margin-bottom: 10px;
    overflow-wrap: anywhere;
    word-wrap: anywhere;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    text-wrap: balance;
}

.link {
    text-decoration: none;
}