.header {
    width: 100%;
    background: linear-gradient(180deg, #080808 40.82%, rgba(8, 8, 8, 0) 100%);
    position: fixed;
    z-index: 4;
}

.content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
}

.nav{
    display: flex;
    align-items: center;
    grid-gap: 30px;  
}

.logo{
    width: 60px;
    height: 60px;
    cursor: pointer;
}

.mobLogo {
    width: 40px;
    height: 40px;
    cursor: pointer;
}
   
.linksList{
    display: flex;
    align-items: center;
    grid-gap: 33px;        
}

.link, .active {
    display: block;
    width: 100%;
    position: relative;
    padding: 5px;
    font-size: 20px;
    color: #66B9EB;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600; 
    transition: 0.3s ease-in-out;
}

.linksList:hover .links .link{
    transform: scale(1.2);
    filter: blur(5px)
}

.linksList:hover .links .active{
    transform: scale(1.2);
    filter: blur(0px)
}

.linksList .links .link:hover {
    transform: scale(1.2);
    filter: blur(0px)
}

.active {
    color: #F2F3F7;
    opacity: 0.5;
}
    
.profile{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    position: relative;
}

.mobHeader {
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: relative;
    z-index: 11;
    display: none;
}

.menuButton {
    padding: 0;
    border: none;
    height: 40px;
    background: transparent;
    border: 3px solid var(--white);
    border-radius: 4px;
    overflow: hidden;
}

.menuBurger {
    transition: all 0.8s linear 0.3s;
}

.menuLine {
    transition: all 0.5s;
    stroke-dasharray: 20 11 20 100;
}

.menuBurgerActive {
    translate: 2px -2px;
    rotate: .125turn;
}

.menuLineActive {
    stroke-dasharray: 20 31 20 100;
    stroke-dashoffset: -31;
}

.mobile_content{
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    padding: 0 20px;
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 12;
    overflow: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.mobLinks{
    display: flex;
    grid-gap: 36px;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
}
  
.cart {
    position: relative;
    display: flex;
    align-items: center;
}

.cartImg, .call {
    width: 30px;
    cursor: pointer;
    transition: all 0.3s;
}

.call {
    height: 24px;
    margin-right: 20px;
}

.cartImg:hover, .call:hover {
    transform: scale(1.2);
}

.amountWrapper {
    background: var(--red);
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    height: 16px;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.amount {
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
}

.mobHeaderContainer {
    display: flex;
}

.db {
    display: block;
}

@media screen and (max-width: 900px) {
    .mobHeader {
        display: flex;
    }
    .content {
        display: none;
    }
}

@media screen and (min-width: 901px) {
    .mobile_content {
        display: none;
    }
} 