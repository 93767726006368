.container {
  position: relative;
  margin-bottom: 20px;
}

.categories {
  max-width: 320px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.search {
  margin-bottom: 0;
  max-width: 400px;
  margin: 0 auto;
}

.headerSearch {
  display: none;
}

@media screen and (min-width: 1170px) {
  .search {
    display: none;
  }

  .headerSearch {
    display: block;
    margin-bottom: 0;
    width: 360px;
  }
}