.input {
    width: 100%;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    border: 1px solid var(--gray);
    border-radius: 6px;
    outline: none;
    text-indent: 10px;
    color: black !important;
    margin-bottom: 10px;
  }
  
.input::placeholder {
    font-size: 16px;
    line-height: 19px;
    color: var(--gray);
    text-indent: 10px;
}