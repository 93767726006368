.footer {
    background: linear-gradient(0deg, #080808 50.82%, rgba(8, 8, 8, 0) 100%);
    z-index: 4;
    padding: 20px 0;
}

.wrapper {
    display: grid;
    gap: 20px;
    width: 100%;
}

.link {
    font-size: 20px;
    color: #F2F3F7;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .link {
        padding-top: 10px;
    }
}