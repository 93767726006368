.form {
    margin: 20px 0;
    max-height: 53vh;
    overflow-y: auto;
    padding: 0 10px;
    border: 3px solid #7c7c7c;
    border-radius: 6px;
    padding-top: 10px;
}

.formHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.formHeaderContainer {
    display: flex;
}

.number {
    background: var(--red);
    color: var(--white);
    width: 15px;
    height: 15px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    font-size: 12px;
    line-height: 16px;
    margin-right: 10px;
}

.complete {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.mainTitle {
    font-size: 18px;
    font-weight: 600;
}

.formTitle {
    display: inline-block;
    margin-bottom: 10px;
    color: var(--gray5);
}

.required {
    color: var(--red);
    font-weight: 600;
    margin-right: 6px;
}

.telInput {
    width: 100%;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    border: 1px solid var(--gray);
    border-radius: 6px;
    outline: none;
    text-indent: 10px;
    color: black !important;
    margin-bottom: 10px;
  }
  
.telInput::placeholder {
    font-size: 16px;
    line-height: 19px;
    color: var(--gray);
    text-indent: 10px;
}

.dn {
    display: none;
}

.red {
    padding: 5px;
    padding-bottom: 0;
    border-radius: 6px;
    border: 3px solid var(--red);
    margin-bottom: 20px;
}

.green {
    border: 3px solid var(--green);
}

.error {
    color: var(--red);
    font-size: 12px;
}

.radioError {
    color: var(--red);
}

.radioGroup {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    margin: 0 20px;
}

.radio {
    display: flex;
    align-items: flex-end;
}

.label {
    display: inline-block;
    margin-top: 10px;
    padding-left: 10px;
    line-height: 0.8;
}

.post {
    margin-top: 10px;
}

.selected {
    margin: 0 0 10px 10px;
}

.small {
    font-size: 13px;
}

.previewFirst {
    padding-top: 10px;
}

.preview {
    padding-left: 28px;
    margin-bottom: 10px;
}

.preview p, .previewFirst p {
    margin-bottom: 2px;
}

.np {
    display: flex;
    align-items: flex-start;
}

.gray {
    color: var(--gray5);
}

.black {
    color: black;
    font-weight: 600;
}

@media screen and (min-width: 768px) {
    .np {
        display: block;
    }

    .br {
        display: none;
    }

    .form {
        padding: 0 20px;
        padding-top: 10px;
        max-height: 58vh;
    }

    .formHeaderContainer {
        align-items: center;
    }

    .number {
        width: 20px;
        height: 20px;
        font-size: 16px;
    }

    .complete {
        width: 23px;
        height: 23px;
    }

    .mainTitle {
        font-size: 22px;
    }
    
    .formTitle {
        font-size: 20px;
    }

    .error {
        font-size: 16px;
    }

    .label {
        font-size: 20px;
    }

    .small, .selected {
        font-size: 18px;
    }
}