.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 20px;
    width: 90%;
    max-height: 84vh;
    background: rgba(255,255,255,0.8);
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    z-index: 3;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.list {
    max-height: 52vh;
    overflow-y: auto;
    padding: 0 10px;
    border: 3px solid #7c7c7c;
    border-radius: 6px;
    padding-top: 10px;
}

.listItem {
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--black3);
    padding-bottom: 10px;
    margin-bottom: 8px;
}

.listItem:last-child {
    border: none;
}

.product {
    display: flex;
}

.productName {
    font-size: 20px;
    padding-top: 8px;
}

.productPrice {
    font-size: 24px;
}

.productFooter {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.img {
    width: 100px;
}

.amount {
    margin: 0 10px;
    font-weight: 600;
    font-size: 24px;
    color: #66B9EB;
}

.btnsContainer {
    width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.mainTitle {
    font-size: 30px;
    font-weight: 700;
}

.close {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.3s;
}

.close:hover {
    transform: scale(1.2);
}

.delete {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 2px;
    transition: all 0.3s;
}

.delete:hover {
    transform: scale(1.2);
}

.total {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0;
}

.totalPrice {
    color: #66B9EB;
    font-size: 24px;
}

.empty {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 60px;
}

.cartImg {
    margin-top: 60px;
    margin-bottom: 20px;
    position: relative;
    left: 50%;
    transform: translateX(-60%);
    width: 120px;
}

@media screen and (min-width: 768px) {
    .list {
        max-height: 56vh;
        padding: 0 20px;
        padding-top: 10px;
   }

   .listItem {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
   }

   .productFooter {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
   }

   .footerBtns {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
   }
}

@media screen and (min-width: 901px) {
    .wrapper {
        max-height: 80vh;
    }

    .list {
        max-height: 52vh;
     }
}

@media screen and (min-width: 1024px) {
    .wrapper {
        width: 57%;
    }
}
