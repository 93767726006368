.button {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    border: 2px solid white;
    background: #66B9EB;
    color: white;
    border-radius: 6px;
    padding: 10px 20px;
    letter-spacing: 1px;
    cursor: pointer;
    position: relative;
    transition: 0.3s ease-in-out;
    outline: none;
    overflow: hidden;
}

.button::before {
    content: '';
    position: absolute;
    inset: 0;
    background: transparent;
    z-index: -1;
    clip-path: circle(0% at 50% 50%);
    transition: 0.3s ease-in-out;
}

.button:hover {
    background: transparent;
}

.button:hover::before {
    clip-path: circle(100% at 50% 50%);
}

.footer {
    position: fixed;
    bottom: 100px;
    z-index: 100;
    background: #B1B1B1;
    width: 80vw;
}

.checkout, .checkout2 {
    color: white;
    border-color: #66B9EB;
    background: #66B9EB;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
}

.checkout2 {
    margin-bottom: 10px;
}

.back {
    color: #7c7c7c;
    border-color: #7c7c7c;
    background: transparent;
    margin: 0 auto;
    display: block;
}

.amount {
    color: white;
    border-color: #66B9EB;
    background: #66B9EB;
    width: 30px;
    padding: 0;
    padding: 3px;
}

.change {
    font-size: 16px;
    padding: 0 5px;
    border: none;
    color: #66B9EB;
    background: transparent;
}

.disabled, .disabled:hover {
    background: #7c7c7c;
    color: var(--white);
    border-color: #7c7c7c;
    cursor: not-allowed;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
}

.disabled2, .disabled2:hover {
    background: #7c7c7c;
    color: var(--white);
    border-color: #7c7c7c;
    cursor: not-allowed;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
}

.category {
    padding: 5px 10px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    opacity: 0.8;
}

.category:hover {
    background: transparent;
    color: var(--white);
    border: 1px solid rgba(255, 255, 255, 1);
    opacity: 1;
}

.category:hover::before {
    clip-path: circle(0% at 50% 50%);
}

.invert {
    color: var(--black3);
    border-color: var(--black3);
    background: transparent;
}

.green {
    background: transparent;
}

.amount::before, .back::before, .change::before, .checkout::before, .checkout2::before {
    background: white;
}

.amount:hover, .change:hover, .checkout:hover, .checkout2:hover {
    background: white;
    color: #66B9EB;
}

.back:hover, .invert:hover {
    background: white;
}

.pagination {
    background: #66B9EB;
}

.pagination:hover, .green:hover, .pagination::before, .green::before {
    background: transparent;
}

@media screen and (min-width: 481px) {
    .footer {
        bottom: 120px;
        width: 60vw;
    }
}

@media screen and (min-width: 768px) {
    .checkout, .back, .disabled, .disabled:hover {
        margin: 0;
    }

    .back {
        margin-right: 20px;
    }

    .change {
        font-size: 18px;
    }
}

@media screen and (min-width: 888px) {
    .footer {
        bottom: 20px;
        width: 40vw;
    }
}